define('ember-cli-mirage/route-handlers/shorthands/base', ['exports', 'ember-cli-mirage/utils/normalize-name', 'ember-cli-mirage/route-handlers/base'], function (exports, _normalizeName, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var BaseShorthandRouteHandler = function (_BaseRouteHandler) {
    _inherits(BaseShorthandRouteHandler, _BaseRouteHandler);

    function BaseShorthandRouteHandler(schema, serializerOrRegistry, shorthand, path) {
      var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

      _classCallCheck(this, BaseShorthandRouteHandler);

      var _this = _possibleConstructorReturn(this, (BaseShorthandRouteHandler.__proto__ || Object.getPrototypeOf(BaseShorthandRouteHandler)).call(this));

      shorthand = shorthand || _this.getModelClassFromPath(path);
      _this.schema = schema;
      _this.serializerOrRegistry = serializerOrRegistry;
      _this.shorthand = shorthand;
      _this.options = options;

      var type = Array.isArray(shorthand) ? 'array' : typeof shorthand === 'undefined' ? 'undefined' : _typeof(shorthand);
      if (type === 'string') {
        var modelClass = _this.schema[(0, _normalizeName.toCollectionName)(shorthand)];
        _this.handle = function (request) {
          return _this.handleStringShorthand(request, modelClass);
        };
      } else if (type === 'array') {
        var modelClasses = shorthand.map(function (modelName) {
          return _this.schema[(0, _normalizeName.toCollectionName)(modelName)];
        });
        _this.handle = function (request) {
          return _this.handleArrayShorthand(request, modelClasses);
        };
      }
      return _this;
    }

    // handleStringShorthand() {
    //
    // }
    //
    // handleArrayShorthand() {
    //
    // }

    return BaseShorthandRouteHandler;
  }(_base.default);

  exports.default = BaseShorthandRouteHandler;
});