define('ember-cli-page-object/-private/execution_context/integration', ['exports', '-jquery', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/execution_context/helpers', 'ember-cli-page-object/-private/better-errors'], function (exports, _jquery, _helpers, _helpers2, _betterErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = IntegrationExecutionContext;
  function IntegrationExecutionContext(pageObjectNode, testContext) {
    this.pageObjectNode = pageObjectNode;
    this.testContext = testContext;
  }

  IntegrationExecutionContext.prototype = {
    run: function run(cb) {
      return cb(this);
    },
    runAsync: function runAsync(cb) {
      var _this = this;

      Ember.run(function () {
        cb(_this);
      });

      return this.chainable();
    },
    chainable: function chainable() {
      return this.pageObjectNode;
    },
    visit: function visit() {},
    click: function click(selector, container) {
      this.$(selector, container).click();
    },
    fillIn: function fillIn(selector, container, options, content) {
      var $selection = this.$(selector, container);

      (0, _helpers2.fillElement)($selection, content, {
        selector: selector,
        pageObjectNode: this.pageObjectNode,
        pageObjectKey: options.pageObjectKey
      });

      $selection.trigger('input');
      $selection.change();
    },
    $: function $(selector, container) {
      if (container) {
        return (0, _jquery.default)(selector, container);
      } else {
        return this.testContext.$(selector);
      }
    },
    triggerEvent: function triggerEvent(selector, container, options, eventName, eventOptions) {
      var event = _jquery.default.Event(eventName, eventOptions);

      if (container) {
        (0, _jquery.default)(selector, container).trigger(event);
      } else {
        this.testContext.$(selector).trigger(event);
      }
    },
    focus: function focus(selector, options) {
      var $selection = this.findWithAssert(selector, options);

      (0, _helpers2.assertFocusable)($selection[0], {
        selector: selector,
        pageObjectNode: this.pageObjectNode,
        pageObjectKey: options.pageObjectKey
      });

      $selection.focus();
    },
    blur: function blur(selector, options) {
      var $selection = this.findWithAssert(selector, options);

      (0, _helpers2.assertFocusable)($selection[0], {
        selector: selector,
        pageObjectNode: this.pageObjectNode,
        pageObjectKey: options.pageObjectKey
      });

      $selection.blur();
    },
    assertElementExists: function assertElementExists(selector, options) {
      var result = void 0;
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      if (container) {
        result = (0, _jquery.default)(selector, container);
      } else {
        result = this.testContext.$(selector);
      }

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }
    },
    find: function find(selector, options) {
      var result = void 0;
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);

      if (container) {
        result = (0, _jquery.default)(selector, container);
      } else {
        result = this.testContext.$(selector);
      }

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      return result;
    },
    findWithAssert: function findWithAssert(selector, options) {
      var result = void 0;
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);

      if (container) {
        result = (0, _jquery.default)(selector, container);
      } else {
        result = this.testContext.$(selector);
      }

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }

      return result;
    }
  };
});