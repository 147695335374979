define('ember-cli-page-object/macros', ['exports', 'ember-cli-page-object/-private/properties/alias', 'ember-cli-page-object/-private/properties/getter'], function (exports, _alias, _getter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'alias', {
    enumerable: true,
    get: function () {
      return _alias.alias;
    }
  });
  Object.defineProperty(exports, 'getter', {
    enumerable: true,
    get: function () {
      return _getter.getter;
    }
  });
});