define('ember-ux-sauce/components/uxs-navbar/desktop-nav', ['exports', 'ember-ux-sauce/templates/components/uxs-navbar/desktop-nav', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _desktopNav, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-navbar__desktop',
    layout: _desktopNav.default
  });
});