define('ember-ux-date-sauce/components/uxs-date-range', ['exports', 'ember-ux-date-sauce/templates/components/uxs-date-range', 'moment', 'ember-ux-date-sauce/utils/moment-shims', 'ember-ux-date-sauce/utils/format-to-range-type', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsDateRange, _moment, _momentShims, _formatToRangeType, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-daterange',
    value: null,
    label: "Date Range",
    layout: _uxsDateRange.default,
    type: null,
    defaultDayFormat: 'DD/MM/YY',
    defaultWeekFormat: 'YYYY-[W]WW',
    defaultMonthFormat: 'YYYY-MM',
    defaultQuarterFormat: 'YYYY-[Q]Q',
    defaultYearFormat: 'YYYY',
    defaultCustomFormat: 'DD/MM/YY',
    minDate: _moment.default.utc('1970', 'YYYY').startOf('year').toDate(),
    maxDate: _moment.default.utc().add(10, 'years').endOf('year').toDate(),
    // Computed
    isDayRange: Ember.computed.equal('type', 'Day'),
    isWeekRange: Ember.computed.equal('type', 'Week'),
    isMonthRange: Ember.computed.equal('type', 'Month'),
    isQuarterRange: Ember.computed.equal('type', 'Quarter'),
    isYearRange: Ember.computed.equal('type', 'Year'),
    isCustomRange: Ember.computed.equal('type', 'Custom'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'rangeOptions', ['Day', 'Week', 'Month', 'Quarter', 'Year', 'Custom']);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var range = (0, _formatToRangeType.default)(Ember.get(this, 'value'));
      Ember.set(this, 'type', range);
    },
    convertDateToFormat: function convertDateToFormat(date, type) {
      switch (type) {
        case 'Day':
          return date.format(Ember.get(this, 'defaultDayFormat'));
        case 'Week':
          return date.format(Ember.get(this, 'defaultWeekFormat'));
        case 'Month':
          return date.format(Ember.get(this, 'defaultMonthFormat'));
        case 'Quarter':
          return date.format(Ember.get(this, 'defaultQuarterFormat'));
        case 'Year':
          return date.format(Ember.get(this, 'defaultYearFormat'));
        case 'Custom':
          var from = date.format(Ember.get(this, 'defaultCustomFormat')),
              to = date.add(1, 'day').format(Ember.get(this, 'defaultCustomFormat'));
          return from + '..' + to;
      }
    },
    convertFormatToDate: function convertFormatToDate(formattedDate, type) {
      switch (type) {
        case 'Day':
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultDayFormat'));
        case 'Week':
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultWeekFormat'));
        case 'Month':
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultMonthFormat'));
        case 'Quarter':
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultQuarterFormat'));
        case 'Year':
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultYearFormat'));
        case 'Custom':
          // get first date in range
          formattedDate = formattedDate.split('..')[0];
          return _moment.default.utc(formattedDate, Ember.get(this, 'defaultCustomFormat'));
      }
    },
    convertDefaultDateToRange: function convertDefaultDateToRange(date, type) {
      switch (type) {
        case 'Day':
          return [date.startOf('day').clone(), date.endOf('day').clone()];
        case 'Week':
          return [date.startOf('isoWeek').clone(), date.endOf('week').clone()];
        case 'Month':
          return [date.startOf('month').clone(), date.endOf('month').clone()];
        case 'Quarter':
          return [(0, _momentShims.startOfQuarter)(date), (0, _momentShims.endOfQuarter)(date)];
        case 'Year':
          return [date.startOf('year').clone(), date.endOf('year').clone()];
        case 'Custom':
          return [date.startOf('day').clone(), date.endOf('day').clone()];
      }
    },

    // Actions
    actions: {
      setRangeType: function setRangeType(type) {

        if (Ember.isEmpty(type)) {
          Ember.set(this, 'type', null);
          return this.send('setRange', null, null, null);
        }

        var currentType = Ember.get(this, 'type'),
            currentValue = Ember.get(this, 'value');

        var date = void 0;

        if (Ember.isEmpty(currentValue)) {
          date = (0, _moment.default)();
        } else {
          date = this.convertFormatToDate(currentValue, currentType);
        }

        var format = this.convertDateToFormat(date, type),
            range = this.convertDefaultDateToRange(date, type);

        Ember.set(this, 'type', type);
        this.send('setRange', range, format, null);
      },
      setRange: function setRange(dates, format, ref) {
        var action = Ember.get(this, 'onChange');
        if (action) {
          action(dates, format, ref);
        }
      }
    }
  });
});