define('ember-ux-sauce/components/uxs-avatar-icon', ['exports', 'ember-ux-sauce/templates/components/uxs-avatar-icon', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsAvatarIcon, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var IconAvatar = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-icon-avatar',
    layout: _uxsAvatarIcon.default,
    // Computed
    bordered: Ember.computed.alias('border'),
    rounded: Ember.computed.alias('round'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', 'rounded', 'selected', '*size', '*style']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        size: 'medium',
        style: 'dark'
      };
    }
  });

  IconAvatar.reopenClass({
    positionalParams: ['icon']
  });

  exports.default = IconAvatar;
});