define('ember-ux-date-sauce/components/uxs-date-range/month', ['exports', 'ember-ux-date-sauce/templates/components/uxs-date-range/month', 'moment'], function (exports, _month, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    layout: _month.default,
    tagName: '',
    format: 'YYYY-MM',
    minDate: _moment.default.utc('2000', 'YYYY').startOf('year').toDate(),
    maxDate: _moment.default.utc('2010', 'YYYY').endOf('year').toDate(),
    // Computed
    month: Ember.computed('value', 'format', function () {
      var value = Ember.get(this, 'value'),
          format = Ember.get(this, 'format');

      return _moment.default.utc(value, format).format('MM');
    }),
    year: Ember.computed('value', 'format', function () {
      var value = Ember.get(this, 'value'),
          format = Ember.get(this, 'format');

      return _moment.default.utc(value, format).format('YYYY');
    }),
    monthOptions: Ember.computed('year', 'minDate', 'maxDate', function () {
      var minDate = Ember.get(this, 'minDate'),
          maxDate = Ember.get(this, 'maxDate'),
          currentYear = Ember.get(this, 'year'),
          options = [],
          date = _moment.default.utc('' + currentYear, 'YYYY').startOf('year');

      for (var i = 0; i < 12; i++) {
        var option = {};

        option.value = date.format('MM');
        option.label = date.format('MMM');

        if (date.isBefore(minDate) || date.isAfter(maxDate)) {
          option.disabled = true;
        }

        options.push(option);

        date.add(1, 'month');
      }

      return options;
    }),
    yearOptions: Ember.computed('minDate', 'maxDate', function () {

      var minDate = Ember.get(this, 'minDate'),
          maxDate = Ember.get(this, 'maxDate'),
          year = (0, _moment.default)(minDate),
          options = [];
      while (!year.isAfter((0, _moment.default)(maxDate), 'year')) {
        options.push(year.format('YYYY'));
        year.add(1, 'year');
      }
      return options;
    }),
    // Actions
    actions: {
      setMonth: function setMonth(selected) {
        var action = Ember.get(this, 'onChange'),
            format = Ember.get(this, 'format'),
            month = Ember.get(selected, "value"),
            year = Ember.get(this, "year"),
            date = (0, _moment.default)(year + '-' + month, 'YYYY-MM'),
            range = [date.startOf('month').clone(), date.endOf('month').clone()];

        if (action) {
          action(range, date.format(format));
        }
      },
      setYear: function setYear(selected) {
        var action = Ember.get(this, 'onChange'),
            minDate = Ember.get(this, 'minDate'),
            maxDate = Ember.get(this, 'maxDate'),
            format = Ember.get(this, 'format'),
            month = Ember.get(this, "month"),
            year = selected;

        var date = (0, _moment.default)(year + '-' + month, 'YYYY-MM');

        // make sure the week selected is not outside the range
        if (date.isBefore(minDate)) {
          date = (0, _moment.default)(minDate);
        } else if (date.isAfter(maxDate)) {
          date = (0, _moment.default)(maxDate);
        }

        var range = [date.startOf('month').clone(), date.endOf('month').clone()];

        if (action) {
          action(range, date.format(format));
        }
      }
    }
  });
});