define('ember-cli-mirage/route-handlers/shorthands/get', ['exports', 'ember-cli-mirage/assert', 'ember-cli-mirage/route-handlers/shorthands/base', 'ember-cli-mirage', 'ember-cli-mirage/utils/inflector'], function (exports, _assert, _base, _emberCliMirage, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var GetShorthandRouteHandler = function (_BaseShorthandRouteHa) {
    _inherits(GetShorthandRouteHandler, _BaseShorthandRouteHa);

    function GetShorthandRouteHandler() {
      _classCallCheck(this, GetShorthandRouteHandler);

      return _possibleConstructorReturn(this, (GetShorthandRouteHandler.__proto__ || Object.getPrototypeOf(GetShorthandRouteHandler)).apply(this, arguments));
    }

    _createClass(GetShorthandRouteHandler, [{
      key: 'handleStringShorthand',
      value: function handleStringShorthand(request, modelClass) {
        var modelName = this.shorthand;
        var camelizedModelName = (0, _inflector.camelize)(modelName);

        (0, _assert.default)(modelClass, 'The route handler for ' + request.url + ' is trying to access the ' + camelizedModelName + ' model, but that model doesn\'t exist. Create it using \'ember g mirage-model ' + modelName + '\'.');

        var id = this._getIdForRequest(request);
        if (id) {
          var model = modelClass.find(id);
          if (!model) {
            return new _emberCliMirage.Response(404);
          } else {
            return model;
          }
        } else if (this.options.coalesce) {
          var ids = this.serializerOrRegistry.getCoalescedIds(request, camelizedModelName);
          if (ids) {
            return modelClass.find(ids);
          }
        }
        return modelClass.all();
      }
    }, {
      key: 'handleArrayShorthand',
      value: function handleArrayShorthand(request, modelClasses) {
        var keys = this.shorthand;
        var id = this._getIdForRequest(request);

        /*
        If the first key is singular and we have an id param in
        the request, we're dealing with the version of the shorthand
        that has a parent model and several has-many relationships.
        We throw an error, because the serializer is the appropriate
        place for this now.
        */
        (0, _assert.default)(!id || (0, _inflector.singularize)(keys[0]) !== keys[0], 'It looks like you\'re using the "Single record with\n      related records" version of the array shorthand, in addition to opting\n      in to the model layer. This shorthand was made when there was no\n      serializer layer. Now that you\'re using models, please ensure your\n      relationships are defined, and create a serializer for the parent\n      model, adding the relationships there.');

        return modelClasses.map(function (modelClass) {
          return modelClass.all();
        });
      }
    }]);

    return GetShorthandRouteHandler;
  }(_base.default);

  exports.default = GetShorthandRouteHandler;
});