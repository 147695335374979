define('ember-ux-sauce/components/uxs-flatpickr', ['exports', 'ember-flatpickr/components/ember-flatpickr', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _emberFlatpickr, _accessible, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberFlatpickr.default.extend(_accessible.default, _bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__input',
    // Computed
    isDate: Ember.computed.equal('type', 'date'),
    isTime: Ember.computed.equal('type', 'time'),
    isDateTime: Ember.computed.equal('type', 'datetime'),
    date: Ember.computed.oneWay('value'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.initModifiers();
      this.initModelComputedProperties();
      this.initDefaultAttributes();
    },

    /**
     * Set the properties to bind to BEM modifier classes
     */
    initModifiers: function initModifiers() {
      this.registerModifiers(['disabled', 'isDate:date', 'isDateTime:datetime', 'error', 'inlineInput:inline', 'isTime:time', 'warning', '*style']);
    },

    /**
     * If this control has a model and name defined
     * we create an alias for the bound value.
     * We also create a one way computed property to
     * read the current validation state of the property.
     */
    initModelComputedProperties: function initModelComputedProperties() {
      var model = this.get('model'),
          propName = this.get('name');

      if (model && propName) {
        Ember.defineProperty(this, 'validator', Ember.computed.oneWay('model.validations.attrs.' + propName));
        // map the value to mode.property - this can be overridden by passing value
        // property into this component
        Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + propName));
      }
    },
    initDefaultAttributes: function initDefaultAttributes() {
      Ember.set(this.attrs, 'enableTime', Ember.get(this, 'enableTime'));
      Ember.set(this.attrs, 'noCalendar', Ember.get(this, 'noCalendar'));
      Ember.set(this.attrs, 'dateFormat', Ember.get(this, 'dateFormat'));
      Ember.set(this.attrs, 'date', Ember.get(this, 'date'));
    }
  });
});