define('ember-ux-sauce/components/uxs-form-time', ['exports', 'ember-ux-sauce/components/uxs-flatpickr'], function (exports, _uxsFlatpickr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uxsFlatpickr.default.extend({
    // Attributes
    type: 'time',
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i'
  });
});