define('ember-ux-sauce/components/uxs-navbar-logo', ['exports', 'ember-ux-sauce/templates/components/uxs-navbar-logo', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsNavbarLogo, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-navbar__logo',
    layout: _uxsNavbarLogo.default
  });
});