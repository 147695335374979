define('ember-ux-sauce/components/uxs-form-datetime', ['exports', 'ember-ux-sauce/components/uxs-flatpickr'], function (exports, _uxsFlatpickr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uxsFlatpickr.default.extend({
    // Attributes
    type: 'datetime',
    enableTime: true,
    noCalendar: false,
    dateFormat: 'd/m/y H:i'
  });
});