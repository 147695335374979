define('ember-bem-sauce/helpers/bem', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bem = bem;
  function bem(params, additionalModifiers) {

    // return params;
    var globalBase = Ember.get(_emberGetConfig.default, 'bem-sauce.globalBaseClass'),
        base = params[0],
        element = params[1],
        modifiers = params[2],
        baseClass = base + '__' + element,
        classNames = Ember.A();

    if (globalBase) {
      classNames.pushObject(globalBase);
    }
    classNames.pushObject(baseClass);

    modifiers.forEach(function (modifier) {
      classNames.pushObject(baseClass + '--' + modifier);
    });

    if (additionalModifiers) for (var modifier in additionalModifiers) {
      if (additionalModifiers[modifier]) {
        classNames.pushObject(baseClass + '--' + modifier);
      }
    }

    return classNames.join(' ');
  }

  exports.default = Ember.Helper.helper(bem);
});