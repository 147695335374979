define('ember-cli-mirage/route-handlers/shorthands/delete', ['exports', 'ember-cli-mirage/assert', 'ember-cli-mirage/route-handlers/shorthands/base', 'ember-cli-mirage/utils/inflector'], function (exports, _assert, _base, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var DeleteShorthandRouteHandler = function (_BaseShorthandRouteHa) {
    _inherits(DeleteShorthandRouteHandler, _BaseShorthandRouteHa);

    function DeleteShorthandRouteHandler() {
      _classCallCheck(this, DeleteShorthandRouteHandler);

      return _possibleConstructorReturn(this, (DeleteShorthandRouteHandler.__proto__ || Object.getPrototypeOf(DeleteShorthandRouteHandler)).apply(this, arguments));
    }

    _createClass(DeleteShorthandRouteHandler, [{
      key: 'handleStringShorthand',
      value: function handleStringShorthand(request, modelClass) {
        var modelName = this.shorthand;
        var camelizedModelName = (0, _inflector.camelize)(modelName);
        (0, _assert.default)(modelClass, 'The route handler for ' + request.url + ' is trying to access the ' + camelizedModelName + ' model, but that model doesn\'t exist. Create it using \'ember g mirage-model ' + modelName + '\'.');

        var id = this._getIdForRequest(request);
        return modelClass.find(id).destroy();
      }
    }, {
      key: 'handleArrayShorthand',
      value: function handleArrayShorthand(request, modelClasses) {
        var id = this._getIdForRequest(request);

        var parent = modelClasses[0].find(id);
        var childTypes = modelClasses.slice(1).map(function (modelClass) {
          return (0, _inflector.pluralize)(modelClass.camelizedModelName);
        });

        // Delete related children
        childTypes.forEach(function (type) {
          return parent[type].destroy();
        });
        parent.destroy();
      }
    }]);

    return DeleteShorthandRouteHandler;
  }(_base.default);

  exports.default = DeleteShorthandRouteHandler;
});