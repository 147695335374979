define('ember-ux-sauce/components/uxs-form-dropdown-multiple', ['exports', 'ember-power-select/components/power-select-multiple', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _powerSelectMultiple, _accessible, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelectMultiple.default.extend(_accessible.default, _bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__input',
    isDropdown: true,
    tagName: 'div',
    // Computed
    dropdownClass: Ember.computed('style', function () {
      var classNames = [],
          base = 'uxs-form__dropdown',
          style = Ember.get(this, 'style');

      classNames.push(base);

      if (style) {
        classNames.push(base + '--' + style);
      }

      return classNames.join(' ');
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.initModifiers();
    },
    initModifiers: function initModifiers() {
      this.registerModifiers(['disabled', 'isDropdown:dropdown', 'error', 'inline', 'warning', '*style']);
    },


    /**
     * If this control has a model and name defined
     * we create an alias for the bound value.
     * We also create a one way computed property to
     * read the current validation state of the property.
     */
    initModelComputedProperties: function initModelComputedProperties() {
      var model = this.get('model'),
          propName = this.get('name');

      if (model && propName) {
        Ember.defineProperty(this, 'validator', Ember.computed.oneWay('model.validations.attrs.' + propName));
        // map the value to mode.property - this can be overridden by passing value
        // property into this component
        Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + propName));
        Ember.defineProperty(this, 'selected', Ember.computed.alias('model.' + propName));
      }
    }
  });
});