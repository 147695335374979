define('ember-ux-sauce/components/uxs-li', ['exports', 'ember-ux-sauce/templates/components/uxs-li', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsLi, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Content = Ember.Component.extend(_textable.default, {
    base: 'uxs-li',
    layout: _uxsLi.default,
    tagName: 'li'
  });

  Content.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Content;
});