define('ember-cli-mirage/serializers/json-api-serializer', ['exports', 'ember-cli-mirage/serializer', 'ember-cli-mirage/utils/inflector', 'lodash/get', 'lodash'], function (exports, _serializer, _inflector, _get2, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var JSONAPISerializer = _serializer.default.extend({
    keyForModel: function keyForModel(modelName) {
      return (0, _inflector.dasherize)(modelName);
    },
    keyForCollection: function keyForCollection(modelName) {
      return (0, _inflector.dasherize)(modelName);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return (0, _inflector.dasherize)(attr);
    },
    keyForRelationship: function keyForRelationship(key) {
      return (0, _inflector.dasherize)(key);
    },
    getHashForPrimaryResource: function getHashForPrimaryResource(resource) {
      var resourceHash = this.getHashForResource(resource);
      var hashWithRoot = { data: resourceHash };
      var addToIncludes = this.getAddToIncludesForResource(resource);

      return [hashWithRoot, addToIncludes];
    },
    getHashForIncludedResource: function getHashForIncludedResource(resource) {
      var serializer = this.serializerFor(resource.modelName);
      var hash = serializer.getHashForResource(resource);
      var hashWithRoot = { included: this.isModel(resource) ? [hash] : hash };
      var addToIncludes = [];

      if (!this.hasQueryParamIncludes()) {
        addToIncludes = this.getAddToIncludesForResource(resource);
      }

      return [hashWithRoot, addToIncludes];
    },
    getHashForResource: function getHashForResource(resource) {
      var _this = this;

      var hash = void 0;

      if (this.isModel(resource)) {
        hash = this._getResourceObjectForModel(resource);
      } else {
        hash = resource.models.map(function (m) {
          return _this._getResourceObjectForModel(m);
        });
      }

      return hash;
    },
    getAddToIncludesForResource: function getAddToIncludesForResource(resource) {
      var relationshipPaths = void 0;

      if (this.hasQueryParamIncludes()) {
        relationshipPaths = this.request.queryParams.include.split(',');
      } else {
        var serializer = this.serializerFor(resource.modelName);
        relationshipPaths = serializer.getKeysForIncluded();
      }

      return this.getAddToIncludesForResourceAndPaths(resource, relationshipPaths);
    },
    getAddToIncludesForResourceAndPaths: function getAddToIncludesForResourceAndPaths(resource, relationshipPaths) {
      var _this2 = this;

      var includes = [];

      relationshipPaths.forEach(function (path) {
        var relationshipNames = path.split('.');
        var newIncludes = _this2.getIncludesForResourceAndPath.apply(_this2, [resource].concat(_toConsumableArray(relationshipNames)));
        includes.push(newIncludes);
      });

      return (0, _lodash.default)(includes).flatten().compact().uniqBy(function (m) {
        return m.toString();
      }).value();
    },
    getIncludesForResourceAndPath: function getIncludesForResourceAndPath(resource) {
      var _this3 = this;

      for (var _len = arguments.length, names = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        names[_key - 1] = arguments[_key];
      }

      var nameForCurrentResource = (0, _inflector.camelize)(names.shift());
      var includes = [];
      var modelsToAdd = [];

      if (this.isModel(resource)) {
        var relationship = resource[nameForCurrentResource];

        if (this.isModel(relationship)) {
          modelsToAdd = [relationship];
        } else if (this.isCollection(relationship)) {
          modelsToAdd = relationship.models;
        }
      } else {
        resource.models.forEach(function (model) {
          var relationship = model[nameForCurrentResource];

          if (_this3.isModel(relationship)) {
            modelsToAdd.push(relationship);
          } else if (_this3.isCollection(relationship)) {
            modelsToAdd = modelsToAdd.concat(relationship.models);
          }
        });
      }

      includes = includes.concat(modelsToAdd);

      if (names.length) {
        modelsToAdd.forEach(function (model) {
          includes = includes.concat(_this3.getIncludesForResourceAndPath.apply(_this3, [model].concat(_toConsumableArray(names))));
        });
      }

      return includes;
    },
    _getResourceObjectForModel: function _getResourceObjectForModel(model) {
      var attrs = this._attrsForModel(model, true);
      delete attrs.id;

      var hash = {
        type: this.typeKeyForModel(model),
        id: model.id,
        attributes: attrs
      };

      return this._maybeAddRelationshipsToResourceObjectForModel(hash, model);
    },
    _maybeAddRelationshipsToResourceObjectForModel: function _maybeAddRelationshipsToResourceObjectForModel(hash, model) {
      var _this4 = this;

      var relationships = model.associationKeys.reduce(function (relationships, key) {
        var relationship = model[key];
        var relationshipKey = _this4.keyForRelationship(key);
        var relationshipHash = {};

        if (_this4.hasLinksForRelationship(model, key)) {
          var serializer = _this4.serializerFor(model.modelName);
          var links = serializer.links(model);
          relationshipHash.links = links[key];
        }

        if (_this4.alwaysIncludeLinkageData || _this4._relationshipIsIncluded(key)) {
          var data = null;
          if (_this4.isModel(relationship)) {
            data = {
              type: _this4.typeKeyForModel(relationship),
              id: relationship.id
            };
          } else if (_this4.isCollection(relationship)) {
            data = relationship.models.map(function (model) {
              return {
                type: _this4.typeKeyForModel(model),
                id: model.id
              };
            });
          }
          relationshipHash.data = data;
        }

        if (!_lodash.default.isEmpty(relationshipHash)) {
          relationships[relationshipKey] = relationshipHash;
        }

        return relationships;
      }, {});

      if (!_lodash.default.isEmpty(relationships)) {
        hash.relationships = relationships;
      }

      return hash;
    },
    hasLinksForRelationship: function hasLinksForRelationship(model, relationshipKey) {
      var serializer = this.serializerFor(model.modelName);
      var links = void 0;
      if (serializer.links) {
        links = serializer.links(model);

        return links[relationshipKey] != null;
      }
    },
    _relationshipIsIncluded: function _relationshipIsIncluded(relationshipKey) {
      if (this.hasQueryParamIncludes()) {
        var relationshipKeyAsString = this.keyForRelationship(relationshipKey);

        return this.request.queryParams.include.split(',').some(function (str) {
          return str.indexOf(relationshipKeyAsString) > -1;
        });
      } else {
        var relationshipPaths = this.getKeysForIncluded();

        return relationshipPaths.includes(relationshipKey);
      }
    },
    getQueryParamIncludes: function getQueryParamIncludes() {
      return (0, _get2.default)(this, 'request.queryParams.include');
    },
    hasQueryParamIncludes: function hasQueryParamIncludes() {
      return !!this.getQueryParamIncludes();
    },
    typeKeyForModel: function typeKeyForModel(model) {
      return (0, _inflector.dasherize)((0, _inflector.pluralize)(model.modelName));
    },
    getCoalescedIds: function getCoalescedIds(request) {
      var ids = request.queryParams && request.queryParams['filter[id]'];
      if (typeof ids === 'string') {
        return ids.split(',');
      }
      return ids;
    }
  });

  JSONAPISerializer.prototype.alwaysIncludeLinkageData = false;

  exports.default = JSONAPISerializer;
});