define("ember-ux-date-sauce/utils/moment-shims", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.startOfQuarter = startOfQuarter;
  exports.endOfQuarter = endOfQuarter;
  function startOfQuarter(date) {
    date = date.startOf('month').clone();

    for (var i = 0; i <= 3; i++) {
      var m = date.format("MM");
      if (m === "01" || m === "04" || m === "07" || m === "10") {
        continue;
      } else {
        date.subtract(1, 'month');
      }
    }
    return date.startOf('month');
  }

  function endOfQuarter(date) {
    date = date.endOf('month').clone();

    for (var i = 0; i <= 3; i++) {
      var m = date.format("MM");
      if (m === "03" || m === "06" || m === "09" || m === "12") {
        continue;
      } else {
        date.add(1, 'month');
      }
    }
    return date.endOf('month');
  }

  exports.default = {
    startOfQuarter: startOfQuarter,
    endOfQuarter: endOfQuarter
  };
});