define('ember-mirage-sauce/mirage-serializers/json-api-serializer', ['exports', 'ember-cli-mirage', 'ember-inflector'], function (exports, _emberCliMirage, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    /**
      Define an array of fields in the model to fuzzy search
       @property searchByFields
      @type {Array}
     */
    searchByFields: Ember.A([]),

    /**
      Query param name for the search parameter
      _Default: "search"
       @property searchKey
      @default "search"
      @type {string}
     */
    searchKey: 'search',

    /**
      Query param name for the sort parameter
      _Default: "sort"
       @property sortKey
      @default "sort"
      @type {string}
     */
    sortKey: 'sort',

    /**
      Query param name for the filter parameters
      _Default: "filter"
       @property filterKey
      @default "filter"
      @type {string}
     */
    filterKey: 'filter',

    /**
      Override the parent serializer to add support for search,
      filter, sort & pagination
       @method serialize
      @access public
      @param {Object} object
      @param {Object} request
      @return {Hash}
     */
    serialize: function serialize(object, request) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments);

      // Add metadata, sort parts of the response, etc.

      // Is this a list response
      if (Array.isArray(json.data)) {
        // Get filter params from request
        var filters = this._extractFilterParams(request.queryParams);
        // Filter data
        json.data = this.filterResponse(json.data, filters);
        // Sort data
        json.data = this.sortResponse(json, Ember.get(request.queryParams, Ember.get(this, 'sortKey')));
        // Paginate?
        if (request.queryParams['page[number]'] && request.queryParams['page[size]']) {
          var page = parseInt(request.queryParams['page[number]']);
          var size = parseInt(request.queryParams['page[size]']);

          json = this.paginate(json, page, size);
        }
      }

      //
      return json;
    },

    /**
      Filter responses by filter params
       _NOTE! to filter by a relationship id it must be included
      in the requests "include" param. Otherwise this serializer
      does not include data from that relationship in it's 'data'_
       @access protected
      @method filterResponse
      @param {Array} data
      @param {Array} filters
      @return {Array}
     */
    filterResponse: function filterResponse(data, filters) {
      var _this = this;

      filters.forEach(function (filter) {
        data = data.filter(function (record) {
          var match = false;
          filter.property = Ember.String.dasherize(filter.property);
          filter.values.forEach(function (value) {
            var attribute = Ember.get(record, 'attributes.' + filter.property);

            // Convert bool to string
            if (typeof attribute === "boolean") {
              attribute = attribute.toString();
            }

            // Check for an attribute match
            if (filter.property === Ember.get(_this, 'searchKey') && value) {
              if (_this.filterBySearch(record, value)) {
                match = true;
              }
            } else if (value === attribute) {
              match = true;
            } else if (filter.property.endsWith('-id')) {
              var relationship = filter.property.replace('-id', ''),
                  path = 'relationships.' + relationship + '.data.id';
              // Check for a relationship match
              if (value === Ember.get(record, path)) {
                match = true;
              }
            }
          });
          return match;
        });
      });
      return data;
    },

    /**
      Check if the model passes search filter
       @access protected
      @method filterBySearch
      @param {object}    record Serialised model instance to search
      @param {string}    term The search term
      @return {boolean}
     */
    filterBySearch: function filterBySearch(record, term) {

      var searchFields = Ember.get(this, 'searchByFields');

      if (Ember.isEmpty(searchFields)) {
        // no search fields - return record
        return true;
      }

      var matched = false;

      searchFields.forEach(function (field) {
        var fieldValue = Ember.get(record, 'attributes.' + Ember.String.dasherize(field));

        if (!Ember.isEmpty(fieldValue) && fieldValue.search(term) !== -1) {
          matched = true;
        }
      });

      return matched;
    },

    /**
      Order responses by sort param
       _Supports one sort param atm..._
      http://jsonapi.org/format/#fetching-sorting
       @access protected
      @method sortResponse
      @param {Array} data
      @param {Array} filters
      @return {Array}
     */
    sortResponse: function sortResponse(json, sort) {

      var desc = false,
          data = json.data;

      if (sort && data.length > 0) {
        // does this sort param start with "-"
        if (sort.indexOf('-') === 0) {
          // sort decending
          desc = true;
          // remove prefixed '-'
          sort = sort.substring(1);
        }
        // find the sort path
        if (this._isAttribute(sort)) {
          var path = this._getAttributePath(sort, data[0]);
          // sort by property
          data = Ember.A(data).sortBy(path);
        } else if (this._isRelatedAttribute(sort)) {
          // sort by related
          data = this._sortByIncludedProperty(data, json.included, sort);
        }
        // reverse sort order?
        if (desc) {
          data = data.reverseObjects();
        }
      }
      return data;
    },

    /**
      Paginate response
       @access protected
      @method paginate
      @param {object} results data to be paginated
      @param {number} page  current page
      @param {number} size  current page size
      @return {object}
     */
    paginate: function paginate(res, page, size) {
      var slicedResults = function slicedResults(results) {
        var start = (page - 1) * size;
        var end = start + size;

        return results.slice(start, end);
      };

      var buildMetadata = function buildMetadata(results) {
        return {
          page: page,
          size: size,
          total: results.length,
          pages: Math.floor(results.length / size)
        };
      };

      res.meta = buildMetadata(res.data);
      res.data = slicedResults(res.data);

      return res;
    },


    // -------
    // PRIVATE
    // -------

    /**
      Extract filter parameters from the request
       @access private
      @param {Object} params
      @return {Array}
     */
    _extractFilterParams: function _extractFilterParams(params) {
      var filters = [];
      for (var key in params) {
        // loop though params and match any that follow the
        // filter[foo] pattern. Then extract foo.
        if (key.substr(0, 6) === Ember.get(this, 'filterKey')) {
          var property = key.substr(7, key.length - 8),
              value = params[key],
              values = null;

          if (value) {
            values = params[key].split(',');
          }

          if (!Ember.isEmpty(values)) {
            filters.pushObject({
              property: property,
              values: values
            });
          }
        }
      }
      return filters;
    },

    /**
      Sort models by a related property
       @access private
      @param {Array} data       Array of serialized models to sort
      @param {Array} included   Collection of included serialized models
      @param {string} sort      Sort property
      @return {Array}
     */
    _sortByIncludedProperty: function _sortByIncludedProperty(data, included, sort) {
      var _this2 = this;

      var idPath = this._getRelatedIdPath(sort, data[0]),
          model = this._getRelatedModel(sort),
          attrPath = this._getRelatedAttributePath(sort, data[0]);

      return data.sort(function (a, b) {
        var aId = Ember.get(a, idPath),
            bId = Ember.get(b, idPath),
            aRelated = _this2._findIncludedModelById(included, model, aId),
            bRelated = _this2._findIncludedModelById(included, model, bId),
            aVal = Ember.get(aRelated, attrPath),
            bVal = Ember.get(bRelated, attrPath),
            aNum = parseFloat(aVal),
            bNum = parseFloat(bVal);

        // are they numbers?
        if (isNaN(aVal) || isNaN(bVal)) {
          return aVal < bVal;
        } else {
          return aNum < bNum;
        }
      });
    },
    _isAttribute: function _isAttribute(path) {
      return path.split('.').length === 1;
    },
    _isRelatedAttribute: function _isRelatedAttribute(path) {
      return path.split('.').length === 2;
    },
    _getRelatedIdPath: function _getRelatedIdPath(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property);
      // destructure property
      var relatedModel = property.split('.')[0];
      // define full path
      var path = 'relationships.' + relatedModel + '.data.id';

      return path;
    },
    _getAttributePath: function _getAttributePath(property, record) {
      // ensure param is underscored
      property = Ember.String.dasherize(property);
      // define full path
      var path = 'attributes.' + property;
      // check if path is found
      if (typeof Ember.get(record, path) === 'undefined') {
        Ember.Logger.warn('Mirage: Could not find path ' + path);
        Ember.Logger.warn(record);
      }
      return path;
    },
    _getRelatedModel: function _getRelatedModel(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property);
      // destructure property
      property = property.split('.')[0];
      return property;
    },
    _getRelatedAttributePath: function _getRelatedAttributePath(property) {
      // ensure param is underscored
      property = Ember.String.dasherize(property);
      // destructure property
      property = property.split('.')[1];
      // define full path
      var path = 'attributes.' + property;

      return path;
    },
    _findIncludedModelById: function _findIncludedModelById(array, model, id) {
      return array.find(function (item) {
        return Ember.get(item, 'type') === (0, _emberInflector.pluralize)(model) && Ember.get(item, 'id') === id;
      });
    },
    _findRecordPath: function _findRecordPath(property, record) {
      var path = void 0;
      // ensure param is underscored
      property = Ember.String.dasherize(property);
      // destructure property

      var _property$split = property.split('.'),
          _property$split2 = _slicedToArray(_property$split, 2),
          a = _property$split2[0],
          b = _property$split2[1];
      // work out if this is a related property or not
      // and return the key


      if (!Ember.isEmpty(b)) {
        path = 'relationships.' + a + '.data.' + b;
      } else {
        path = 'attributes.' + a;
      }
      // check if path is found
      if (typeof Ember.get(record, path) === 'undefined') {
        Ember.Logger.warn('Mirage: Could not find path ' + path);
        Ember.Logger.warn(record);
      }
      // warn user else
      return path;
    }
  });
});