define('ember-cli-mirage/utils/read-modules', ['exports', 'lodash/camelCase', 'ember-cli-mirage/utils/inflector', 'require'], function (exports, _camelCase2, _inflector, _require2) {
  /* global requirejs, require */
  /* eslint-env node */
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (prefix) {
    var modules = ['factories', 'fixtures', 'scenarios', 'models', 'serializers', 'identity-managers'];
    var mirageModuleRegExp = new RegExp('^' + prefix + '/mirage/(' + modules.join('|') + ')');
    var modulesMap = modules.reduce(function (memo, name) {
      memo[(0, _camelCase2.default)(name)] = {};
      return memo;
    }, {});

    Object.keys(requirejs.entries).filter(function (key) {
      return mirageModuleRegExp.test(key);
    }).forEach(function (moduleName) {
      if (moduleName.match('.jshint')) {
        // ignore autogenerated .jshint files
        return;
      }
      var moduleParts = moduleName.split('/');
      var moduleType = (0, _camelCase2.default)(moduleParts[moduleParts.length - 2]);
      var moduleKey = moduleParts[moduleParts.length - 1];
      (true && !(moduleParts[moduleParts.length - 3] === 'mirage') && Ember.assert('Subdirectories under ' + moduleType + ' are not supported', moduleParts[moduleParts.length - 3] === 'mirage'));


      if (moduleType === 'scenario') {
        (true && !(moduleKey !== 'default') && Ember.assert('Only scenario/default.js is supported at this time.', moduleKey !== 'default'));
      }

      /*
        Ensure fixture keys are pluralized
      */
      if (moduleType === 'fixtures') {
        moduleKey = (0, _inflector.pluralize)(moduleKey);
      }

      var module = (0, _require2.default)(moduleName, null, null, true);
      if (!module) {
        throw new Error(moduleName + ' must export a ' + moduleType);
      }

      var data = module.default;

      modulesMap[moduleType][(0, _camelCase2.default)(moduleKey)] = data;
    });

    return modulesMap;
  };
});