define('ember-cli-mirage/utils/extend', ['exports', 'lodash/assign', 'lodash/has'], function (exports, _assign2, _has2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = extend;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function extend(protoProps, staticProps) {
    var Parent = this;

    var Child = function (_Parent) {
      _inherits(Child, _Parent);

      function Child() {
        var _ref;

        _classCallCheck(this, Child);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var _this = _possibleConstructorReturn(this, (_ref = Child.__proto__ || Object.getPrototypeOf(Child)).call.apply(_ref, [this].concat(args)));

        // The constructor function for the new subclass is optionally defined by you
        // in your `extend` definition
        if (protoProps && (0, _has2.default)(protoProps, 'constructor')) {
          var _protoProps$construct;

          (_protoProps$construct = protoProps.constructor).call.apply(_protoProps$construct, [_this].concat(args));
        }
        return _this;
      }

      return Child;
    }(Parent);

    // Add static properties to the constructor function, if supplied.

    (0, _assign2.default)(Child, Parent, staticProps);

    // Add prototype properties (instance properties) to the subclass,
    // if supplied.
    if (protoProps) {
      (0, _assign2.default)(Child.prototype, protoProps);
    }

    return Child;
  }
});