define('ember-cli-page-object/-private/execution_context', ['exports', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/is-rfc268-test', 'ember-cli-page-object/-private/execution_context/acceptance', 'ember-cli-page-object/-private/execution_context/integration', 'ember-cli-page-object/-private/execution_context/rfc268'], function (exports, _helpers, _isRfc268Test, _acceptance, _integration, _rfc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getExecutionContext = getExecutionContext;
  exports.register = register;


  var executioncontexts = {
    acceptance: _acceptance.default,
    integration: _integration.default,
    rfc268: _rfc.default
  };

  /*
   * @private
   */
  function getExecutionContext(pageObjectNode) {
    // Our `getContext(pageObjectNode)` will return a context only if the test
    // called `page.setContext(this)`, which is only supposed to happen in
    // integration tests (i.e. pre-RFC232/RFC268). However, the integration
    // context does work with RFC232 (`setupRenderingContext()`) tests, and before
    // the RFC268 execution context was implemented, some users may have migrated
    // their tests to RFC232 tests, leaving the `page.setContext(this)` in place.
    // So, in order to not break those tests, we need to check for that case
    // first, and only if that hasn't happened, check to see if we're in an
    // RFC232/RFC268 test, and if not, fall back on assuming a pre-RFC268
    // acceptance test, which is the only remaining supported scenario.
    var testContext = (0, _helpers.getContext)(pageObjectNode);
    var context = void 0;
    if (testContext) {
      context = 'integration';
    } else if ((0, _isRfc268Test.default)()) {
      context = 'rfc268';
    } else {
      context = 'acceptance';
    }

    return new executioncontexts[context](pageObjectNode, testContext);
  }

  /*
   * @private
   */
  function register(type, definition) {
    executioncontexts[type] = definition;
  }
});