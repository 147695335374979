define('ember-ux-sauce/components/uxs-hero', ['exports', 'ember-ux-sauce/templates/components/uxs-hero', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsHero, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-hero',
    layout: _uxsHero.default
  });
});