define('ember-ux-date-sauce/components/uxs-date-range/day', ['exports', 'ember-ux-date-sauce/templates/components/uxs-date-range/day', 'moment'], function (exports, _day, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    date: null,
    tagName: '',
    layout: _day.default,
    format: 'DD/MM/YY',
    // Actions
    actions: {
      setDate: function setDate(dates, formattedDate, ref) {
        var action = Ember.get(this, 'onChange'),
            format = Ember.get(this, 'format'),
            day = _moment.default.utc(formattedDate, format),
            range = [day.startOf('day').clone(), day.endOf('day').clone()];

        if (action) {
          action(range, formattedDate, ref);
        }
      }
    }
  });
});