define('ember-prop-types/utils/validators/ember-component', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;

  exports['default'] = function (ctx, name, value, def, logErrors, throwErrors) {
    var isObject = typeOf(value) === 'object';

    var valid = isObject && Object.keys(value).some(function (key) {
      // NOTE: this is based on internal API and thus could break without warning.
      return key.indexOf('COMPONENT_CELL') > -1 || // Pre Glimmer 2
      key.indexOf('COMPONENT DEFINITION') === 0 // Glimmer 2
      ;
    });

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Expected property ' + name + ' to be an Ember.Component but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.EmberComponent validator
 */