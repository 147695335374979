define('ember-cli-page-object/-private/properties/collection/legacy', ['exports', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/create', 'ember-cli-page-object/-private/properties/count', 'ceibo'], function (exports, _helpers, _create, _count, _ceibo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.collection = collection;


  var arrayDelegateMethods = ['map', 'filter', 'mapBy', 'filterBy', 'forEach']; /* global Symbol */


  function merge(target) {
    for (var _len = arguments.length, objects = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      objects[_key - 1] = arguments[_key];
    }

    objects.forEach(function (o) {
      return (0, _helpers.assign)(target, o);
    });

    return target;
  }

  function generateEnumerable(node, definition, item, key) {
    var enumerable = merge({}, definition);

    if (typeof enumerable.count === 'undefined') {
      enumerable.count = (0, _count.count)(item.itemScope);
    }

    if (typeof enumerable.toArray === 'undefined') {
      enumerable.toArray = toArrayMethod(node, item, key);
      arrayDelegateMethods.forEach(function (method) {
        return delegateToArray(enumerable, method);
      });
    }

    var collection = (0, _create.create)(enumerable, { parent: node });

    if (typeof Symbol !== 'undefined' && Symbol.iterator) {
      collection[Symbol.iterator] = iteratorMethod;
    }

    // Change the key of the root node
    _ceibo.default.meta(collection).key = key + '()';

    return collection;
  }

  function generateItem(node, index, definition, key) {
    var filters = merge({}, { scope: definition.scope, at: index });
    var scope = (0, _helpers.buildSelector)({}, definition.itemScope, filters);

    var tree = (0, _create.create)(merge({
      testContainer: definition.testContainer
    }, definition.item, {
      scope: scope,
      resetScope: definition.resetScope
    }), { parent: node });

    // Change the key of the root node
    _ceibo.default.meta(tree).key = key + '(' + index + ')';

    return tree;
  }

  function toArrayMethod(node, definition, key) {
    return function () {
      var array = Ember.A();
      var index = void 0;
      var count = void 0;

      for (index = 0, count = this.count; index < count; index++) {
        array.push(generateItem(node, index, definition, key));
      }

      return array;
    };
  }

  function delegateToArray(enumerable, method) {
    if (typeof enumerable[method] === 'undefined') {
      enumerable[method] = function () {
        var _toArray;

        return (_toArray = this.toArray())[method].apply(_toArray, arguments);
      };
    }
  }

  function iteratorMethod() {
    var i = 0;
    var items = this.toArray();
    var next = function next() {
      return { done: i >= items.length, value: items[i++] };
    };

    return { next: next };
  }

  function collection(definition) {
    definition = (0, _helpers.assign)({}, definition);

    var item = {
      scope: definition.scope,
      itemScope: definition.itemScope,
      resetScope: definition.resetScope,
      item: definition.item,
      testContainer: definition.testContainer
    };

    delete definition.item;
    delete definition.itemScope;

    return {
      isDescriptor: true,

      get: function get(key) {
        var _this = this;

        return function (index) {
          if (typeof index === 'number') {
            return generateItem(_this, index, item, key);
          } else {
            return generateEnumerable(_this, definition, item, key);
          }
        };
      }
    };
  }
});