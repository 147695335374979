define('ember-cli-mirage/serializers/active-model-serializer', ['exports', 'ember-cli-mirage/serializer', 'ember-cli-mirage/utils/inflector'], function (exports, _serializer, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    normalizeIds: false,
    keyForModel: function keyForModel(type) {
      return (0, _inflector.underscore)(type);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return (0, _inflector.underscore)(attr);
    },
    keyForRelationship: function keyForRelationship(type) {
      return (0, _inflector.pluralize)((0, _inflector.underscore)(type));
    },
    keyForEmbeddedRelationship: function keyForEmbeddedRelationship(attributeName) {
      return (0, _inflector.underscore)(attributeName);
    },
    keyForRelationshipIds: function keyForRelationshipIds(type) {
      return (0, _inflector.underscore)((0, _inflector.singularize)(type)) + '_ids';
    },
    keyForForeignKey: function keyForForeignKey(relationshipName) {
      return (0, _inflector.underscore)(relationshipName) + '_id';
    },
    keyForPolymorphicForeignKeyId: function keyForPolymorphicForeignKeyId(relationshipName) {
      return (0, _inflector.underscore)(relationshipName) + '_id';
    },
    keyForPolymorphicForeignKeyType: function keyForPolymorphicForeignKeyType(relationshipName) {
      return (0, _inflector.underscore)(relationshipName) + '_type';
    },
    normalize: function normalize(payload) {
      var _this = this;

      var type = Object.keys(payload)[0];
      var attrs = payload[type];
      var modelName = (0, _inflector.camelize)(type);
      var modelClass = this.schema.modelClassFor(modelName);
      var belongsToAssociations = modelClass.belongsToAssociations,
          hasManyAssociations = modelClass.hasManyAssociations;

      var belongsToKeys = Object.keys(belongsToAssociations);
      var hasManyKeys = Object.keys(hasManyAssociations);

      var jsonApiPayload = {
        data: {
          type: (0, _inflector.pluralize)(type),
          attributes: {}
        }
      };
      if (attrs.id) {
        jsonApiPayload.data.id = attrs.id;
      }

      var relationships = {};

      Object.keys(attrs).forEach(function (key) {
        if (key !== 'id') {
          if (_this.normalizeIds) {
            if (belongsToKeys.includes(key)) {
              var association = belongsToAssociations[key];
              var associationModel = association.modelName;
              relationships[(0, _inflector.dasherize)(key)] = {
                data: {
                  type: associationModel,
                  id: attrs[key]
                }
              };
            } else if (hasManyKeys.includes(key)) {
              var _association = hasManyAssociations[key];
              var _associationModel = _association.modelName;
              var data = attrs[key].map(function (id) {
                return {
                  type: _associationModel,
                  id: id
                };
              });
              relationships[(0, _inflector.dasherize)(key)] = { data: data };
            } else {
              jsonApiPayload.data.attributes[(0, _inflector.dasherize)(key)] = attrs[key];
            }
          } else {
            jsonApiPayload.data.attributes[(0, _inflector.dasherize)(key)] = attrs[key];
          }
        }
      });
      if (Object.keys(relationships).length) {
        jsonApiPayload.data.relationships = relationships;
      }

      return jsonApiPayload;
    },
    getCoalescedIds: function getCoalescedIds(request) {
      return request.queryParams && request.queryParams.ids;
    }
  });
});