define('ember-cli-page-object/-private/execution_context/native-events-context', ['exports', '-jquery', 'ember-native-dom-helpers', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/execution_context/helpers', 'ember-cli-page-object/-private/better-errors'], function (exports, _jquery, _emberNativeDomHelpers, _helpers, _helpers2, _betterErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = ExecutionContext;


  var KEYBOARD_EVENT_TYPES = ['keydown', 'keypress', 'keyup'];

  function ExecutionContext(pageObjectNode, testContext) {
    this.pageObjectNode = pageObjectNode;
    this.testContext = testContext;
  }

  ExecutionContext.prototype = {
    run: function run(cb) {
      return cb(this);
    },
    runAsync: function runAsync() {
      throw new Error('not implemented');
    },
    chainable: function chainable() {
      return this.pageObjectNode;
    },
    click: function click(selector, container) {
      var el = this.$(selector, container)[0];
      (0, _emberNativeDomHelpers.click)(el);
    },
    fillIn: function fillIn(selector, container, options, content) {
      var _this = this;

      var elements = this.$(selector, container).toArray();

      elements.forEach(function (el) {
        (0, _helpers2.fillElement)(el, content, {
          selector: selector,
          pageObjectNode: _this.pageObjectNode,
          pageObjectKey: options.pageObjectKey
        });

        (0, _emberNativeDomHelpers.triggerEvent)(el, 'input');
        (0, _emberNativeDomHelpers.triggerEvent)(el, 'change');
      });
    },
    $: function $(selector, container) {
      if (container) {
        return (0, _jquery.default)(selector, container);
      } else {
        // @todo: we should fixed usage of private `_element`
        // after https://github.com/emberjs/ember-test-helpers/issues/184 is resolved
        var testsContainer = this.testContext ? this.testContext._element : '#ember-testing';

        return (0, _jquery.default)(selector, testsContainer);
      }
    },
    triggerEvent: function triggerEvent(selector, container, options, eventName, eventOptions) {
      var element = this.$(selector, container)[0];

      // `keyCode` is a deprecated property.
      // @see: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      // Due to this deprecation `ember-native-dom-helpers` doesn't accept `keyCode` as a `KeyboardEvent` option.
      if (typeof eventOptions.key === 'undefined' && typeof eventOptions.keyCode !== 'undefined') {
        eventOptions.key = eventOptions.keyCode.toString();
        delete eventOptions.keyCode;
      }

      if (KEYBOARD_EVENT_TYPES.indexOf(eventName) > -1) {
        (0, _emberNativeDomHelpers.keyEvent)(element, eventName, eventOptions.key, eventOptions);
      } else {
        (0, _emberNativeDomHelpers.triggerEvent)(element, eventName, eventOptions);
      }
    },
    focus: function focus(selector, options) {
      var element = this.findWithAssert(selector, options)[0];

      (0, _helpers2.assertFocusable)(element, {
        selector: selector,
        pageObjectNode: this.pageObjectNode,
        pageObjectKey: options.pageObjectKey
      });

      (0, _emberNativeDomHelpers.focus)(element);
    },
    blur: function blur(selector, options) {
      var element = this.findWithAssert(selector, options)[0];

      (0, _helpers2.assertFocusable)(element, {
        selector: selector,
        pageObjectNode: this.pageObjectNode,
        pageObjectKey: options.pageObjectKey
      });

      (0, _emberNativeDomHelpers.blur)(element);
    },
    assertElementExists: function assertElementExists(selector, options) {
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      var result = this.$(selector, container);

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }
    },
    find: function find(selector, options) {
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);

      var result = this.$(selector, container);

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      return result;
    },
    findWithAssert: function findWithAssert(selector, options) {
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');

      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);

      var result = this.$(selector, container);

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      return result;
    }
  };
});