define('ember-cli-page-object/-private/compatibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getContext = getContext;
  exports.visit = visit;
  exports.click = click;
  exports.fillIn = fillIn;
  exports.triggerEvent = triggerEvent;
  exports.focus = focus;
  exports.blur = blur;
  var _window = window,
      _require2 = _window.require;


  var helpers = void 0;
  var waitFn = void 0;

  if (_require2.has('@ember/test-helpers')) {
    helpers = _require2('@ember/test-helpers');
  } else {
    helpers = {
      getContext: function getContext() {
        return null;
      }
    };
  }

  if (_require2.has('ember-test-helpers/wait')) {
    // This is implemented as a function that calls `ember-test-helpers/wait`
    // rather than just assigning `helpers.wait = require(...).default` because
    // since this code executes while modules are initially loading, under certain
    // conditions `ember-test-helpers/wait` can still be in the pending state
    // at this point, so its exports are still undefined.
    waitFn = function waitFn() {
      var _require;

      return (_require = _require2('ember-test-helpers/wait')).default.apply(_require, arguments);
    };
  } else {
    waitFn = function waitFn() {
      throw new Error('ember-test-helpers or @ember/test-helpers must be installed');
    };
  }

  function getContext() {
    var _helpers;

    return (_helpers = helpers).getContext.apply(_helpers, arguments);
  }
  function visit() {
    var _helpers2;

    return (_helpers2 = helpers).visit.apply(_helpers2, arguments);
  }
  function click() {
    var _helpers3;

    return (_helpers3 = helpers).click.apply(_helpers3, arguments);
  }
  function fillIn() {
    var _helpers4;

    return (_helpers4 = helpers).fillIn.apply(_helpers4, arguments);
  }
  function triggerEvent() {
    var _helpers5;

    return (_helpers5 = helpers).triggerEvent.apply(_helpers5, arguments);
  }
  function focus() {
    var _helpers6;

    return (_helpers6 = helpers).focus.apply(_helpers6, arguments);
  }
  function blur() {
    var _helpers7;

    return (_helpers7 = helpers).blur.apply(_helpers7, arguments);
  }
  var wait = exports.wait = waitFn;
});