define('ember-ux-sauce/components/uxs-search', ['exports', 'ember-ux-sauce/templates/components/uxs-search', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsSearch, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    base: 'uxs-search',
    closeIcon: 'close',
    layout: _uxsSearch.default,
    searchIcon: 'search',
    // tagName: '',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['showSearch:open']);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // If we have a search term, set the showSearch state
      if (!Ember.isEmpty(Ember.get(this, 'value'))) {
        Ember.set(this, 'showSearch', true);
      }
    },

    // Actions
    actions: {
      openSearch: function openSearch() {
        Ember.set(this, 'showSearch', true);
        // Set the search input focus
        Ember.run.later(function () {
          Ember.$('.uxs-search__input').focus();
        }, 100);
      },
      closeSearch: function closeSearch() {
        Ember.set(this, 'showSearch', false);
        // Remove search term on close
        Ember.set(this, 'value', '');
      }
    }
  });
});