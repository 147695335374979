define('ember-ux-sauce/components/uxs-avatar', ['exports', 'ember-ux-sauce/templates/components/uxs-avatar', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsAvatar, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Avatar = Ember.Component.extend(_bemComponent.default, _testable.default, {
    attributeBindings: ['src'],
    base: 'uxs-avatar',
    tagName: 'img',
    layout: _uxsAvatar.default,
    // Computed
    bordered: Ember.computed.alias('border'),
    rounded: Ember.computed.alias('round'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', 'rounded', '*size', '*style']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        size: 'medium',
        style: 'dark'
      };
    }
  });

  Avatar.reopenClass({
    positionalParams: ['src']
  });

  exports.default = Avatar;
});