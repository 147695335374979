define('ember-ux-sauce/components/uxs-avatar-text', ['exports', 'ember-ux-sauce/templates/components/uxs-avatar-text', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsAvatarText, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TextAvatar = Ember.Component.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-text-avatar',
    layout: _uxsAvatarText.default,
    // Computed
    bordered: Ember.computed.alias('border'),
    rounded: Ember.computed.alias('round'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', 'rounded', '*size', '*style']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        size: 'medium',
        style: 'dark'
      };
    }
  });

  TextAvatar.reopenClass({
    positionalParams: ['text']
  });

  exports.default = TextAvatar;
});