define('ember-ux-sauce/components/uxs-app', ['exports', 'ember-ux-sauce/templates/components/uxs-app', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsApp, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-app',
    layout: _uxsApp.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['fixedNav:has-fixed-navbar']);
    }
  });
});