define('ember-cli-page-object/-private/execution_context/integration-native-events', ['exports', 'ember-cli-page-object/-private/execution_context/native-events-context'], function (exports, _nativeEventsContext) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = IntegrationNativeEventsExecutionContext;
  function IntegrationNativeEventsExecutionContext(pageObjectNode, testContext) {
    _nativeEventsContext.default.call(this, pageObjectNode, testContext);
  }

  IntegrationNativeEventsExecutionContext.prototype = Object.create(_nativeEventsContext.default.prototype);

  IntegrationNativeEventsExecutionContext.prototype.visit = function () {};

  IntegrationNativeEventsExecutionContext.prototype.runAsync = function (cb) {
    var _this = this;

    Ember.run(function () {
      cb(_this);
    });

    return this.chainable();
  };
});