define('ember-cli-page-object/extend', ['exports', 'ember-cli-page-object/-private/extend/find-element', 'ember-cli-page-object/-private/extend/find-element-with-assert', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/execution_context', 'ember-cli-page-object/-private/execution_context/integration-native-events', 'ember-cli-page-object/-private/execution_context/acceptance-native-events', 'ember-cli-page-object/-private/execution_context/integration', 'ember-cli-page-object/-private/execution_context/acceptance'], function (exports, _findElement, _findElementWithAssert, _helpers, _execution_context, _integrationNativeEvents, _acceptanceNativeEvents, _integration, _acceptance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useNativeEvents = exports.registerExecutionContext = exports.fullScope = exports.getContext = exports.buildSelector = exports.findElementWithAssert = exports.findElement = undefined;
  Object.defineProperty(exports, 'findElement', {
    enumerable: true,
    get: function () {
      return _findElement.findElement;
    }
  });
  Object.defineProperty(exports, 'findElementWithAssert', {
    enumerable: true,
    get: function () {
      return _findElementWithAssert.findElementWithAssert;
    }
  });
  Object.defineProperty(exports, 'buildSelector', {
    enumerable: true,
    get: function () {
      return _helpers.buildSelector;
    }
  });
  Object.defineProperty(exports, 'getContext', {
    enumerable: true,
    get: function () {
      return _helpers.getContext;
    }
  });
  Object.defineProperty(exports, 'fullScope', {
    enumerable: true,
    get: function () {
      return _helpers.fullScope;
    }
  });


  function useNativeEvents() {
    var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (flag) {
      (0, _execution_context.register)('integration', _integrationNativeEvents.default);
      (0, _execution_context.register)('acceptance', _acceptanceNativeEvents.default);
    } else {
      (0, _execution_context.register)('integration', _integration.default);
      (0, _execution_context.register)('acceptance', _acceptance.default);
    }
  }

  exports.registerExecutionContext = _execution_context.register;
  exports.useNativeEvents = useNativeEvents;
});