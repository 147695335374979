define('ember-ux-date-sauce/utils/format-to-range', ['exports', 'ember-ux-date-sauce/utils/moment-shims', 'ember-ux-date-sauce/utils/format-to-range-type', 'moment'], function (exports, _momentShims, _formatToRangeType, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formatToRange;
  function formatToRange(formattedDate) {
    var type = (0, _formatToRangeType.default)(formattedDate);

    switch (type) {
      case "Day":
        var day = _moment.default.utc(formattedDate, 'DD/MM/YY');
        return [day.startOf('day').clone(), day.endOf('day').clone()];
      case "Week":
        var week = _moment.default.utc(formattedDate, 'YYYY-[W]WW');
        return [week.startOf('isoWeek').clone(), week.endOf('week').clone()];
      case "Month":
        var month = _moment.default.utc(formattedDate, 'YYYY-MM');
        return [month.startOf('month').clone(), month.endOf('month').clone()];
      case "Quarter":
        var quarter = _moment.default.utc(formattedDate, 'YYYY-[Q]Q');
        return [(0, _momentShims.startOfQuarter)(quarter), (0, _momentShims.endOfQuarter)(quarter)];
      case "Year":
        var year = _moment.default.utc(formattedDate, 'YYYY');
        return [year.startOf('year').clone(), year.endOf('year').clone()];
      case "Custom":
        var date = formattedDate.split('..'),
            start = _moment.default.utc(date[0], 'DD/MM/YY'),
            end = _moment.default.utc(date[1], 'DD/MM/YY');
        return [start.startOf('day'), end.endOf('day')];
      default:
        return null;
    }
  }
});