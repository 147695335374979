define('ember-cli-page-object/-private/properties/focusable', ['exports', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/execution_context'], function (exports, _helpers, _execution_context) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.focusable = focusable;


  /**
   *
   * Focuses element matched by selector.
   *
   * @example
   *
   * // <input class="name">
   * // <input class="email">
   *
   * const page = PageObject.create({
   *   focus: focusable('.name')
   * });
   *
   * // focuses on element with selector '.name'
   * page.focus();
   *
   * @example
   *
   * // <div class="scope">
   * //   <input class="name">
   * // </div>
   * // <input class="email">
   *
   * const page = PageObject.create({
   *   focus: focusable('.name', { scope: '.scope' })
   * });
   *
   * // focuses on element with selector '.scope .name'
   * page.focus();
   *
   * @example
   *
   * // <div class="scope">
   * //   <input class="name">
   * // </div>
   * // <input class="email">
   *
   * const page = PageObject.create({
   *   scope: '.scope',
   *   focus: focusable('.name')
   * });
   *
   * // focuses on element with selector '.scope .name'
   * page.focus();
   *
   * @public
   *
   * @param {string} selector - CSS selector of the element which will be focused
   * @param {Object} options - Additional options
   * @param {string} options.scope - Nests provided scope within parent's scope
   * @param {number} options.at - Reduce the set of matched elements to the one at the specified index
   * @param {boolean} options.resetScope - Ignore parent scope
   * @param {string} options.testContainer - Context where to search elements in the DOM
   * @return {Descriptor}
  */
  function focusable(selector) {
    var userOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return {
      isDescriptor: true,

      get: function get(key) {
        return function () {
          var executionContext = (0, _execution_context.getExecutionContext)(this);
          var options = (0, _helpers.assign)({ pageObjectKey: key + '()' }, userOptions);

          return executionContext.runAsync(function (context) {
            return context.focus(selector, options);
          });
        };
      }
    };
  }
});