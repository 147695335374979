define('ember-cli-page-object/-private/properties/collection/main', ['exports', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/create', 'ember-cli-page-object/-private/properties/count', 'ceibo'], function (exports, _helpers, _create, _count, _ceibo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Collection = undefined;
  exports.collection = collection;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Collection = exports.Collection = function () {
    function Collection(scope, definition, parent, key) {
      _classCallCheck(this, Collection);

      this.scope = scope;
      this.definition = definition || {};
      this.parent = parent;
      this.key = key;

      this._itemCounter = (0, _create.create)({
        count: (0, _count.count)(scope, {
          resetScope: this.definition.resetScope,
          testContainer: this.definition.testContainer
        })
      }, { parent: parent });

      this._items = [];
    }

    _createClass(Collection, [{
      key: 'objectAt',
      value: function objectAt(index) {
        var key = this.key;


        if (typeof this._items[index] === 'undefined') {
          var scope = this.scope,
              definition = this.definition,
              parent = this.parent;

          var itemScope = (0, _helpers.buildSelector)({}, scope, { at: index });

          var finalizedDefinition = (0, _helpers.assign)({}, definition);

          finalizedDefinition.scope = itemScope;

          var tree = (0, _create.create)(finalizedDefinition, { parent: parent });

          // Change the key of the root node
          _ceibo.default.meta(tree).key = key + '[' + index + ']';

          this._items[index] = tree;
        }

        return this._items[index];
      }
    }, {
      key: 'filter',
      value: function filter() {
        var _toArray;

        return (_toArray = this.toArray()).filter.apply(_toArray, arguments);
      }
    }, {
      key: 'filterBy',
      value: function filterBy() {
        var _toArray2;

        return (_toArray2 = this.toArray()).filterBy.apply(_toArray2, arguments);
      }
    }, {
      key: 'forEach',
      value: function forEach() {
        var _toArray3;

        return (_toArray3 = this.toArray()).forEach.apply(_toArray3, arguments);
      }
    }, {
      key: 'map',
      value: function map() {
        var _toArray4;

        return (_toArray4 = this.toArray()).map.apply(_toArray4, arguments);
      }
    }, {
      key: 'mapBy',
      value: function mapBy() {
        var _toArray5;

        return (_toArray5 = this.toArray()).mapBy.apply(_toArray5, arguments);
      }
    }, {
      key: 'toArray',
      value: function toArray() {
        var length = this.length;


        var array = Ember.A();

        for (var i = 0; i < length; i++) {
          array.push(this.objectAt(i));
        }

        return array;
      }
    }, {
      key: 'length',
      get: function get() {
        return this._itemCounter.count;
      }
    }]);

    return Collection;
  }();

  if (typeof Symbol !== 'undefined' && Symbol.iterator) {
    Collection.prototype[Symbol.iterator] = function () {
      var i = 0;
      var items = this.toArray();
      var next = function next() {
        return { done: i >= items.length, value: items[i++] };
      };

      return { next: next };
    };
  }

  function proxyIt(instance) {
    return new window.Proxy(instance, {
      get: function get(target, name) {
        if (typeof name === 'number' || typeof name === 'string') {
          var index = parseInt(name, 10);

          if (!isNaN(index)) {
            return target.objectAt(index);
          }
        }

        return target[name];
      }
    });
  }

  function collection(scope, definition) {
    var descriptor = {
      isDescriptor: true,

      setup: function setup(node, key) {
        // Set the value on the descriptor so that it will be picked up and applied by Ceibo.
        // This does mutate the descriptor, but because `setup` is always called before the
        // value is assigned we are guaranteed to get a new, unique Collection instance each time.
        descriptor.value = new Collection(scope, definition, node, key);

        if (window.Proxy) {
          descriptor.value = proxyIt(descriptor.value);
        }
      }
    };

    return descriptor;
  }
});