define('ember-ux-date-sauce/components/uxs-date-range/year', ['exports', 'ember-ux-date-sauce/templates/components/uxs-date-range/year', 'moment'], function (exports, _year, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    layout: _year.default,
    tagName: '',
    format: "YYYY",
    minDate: _moment.default.utc('1970', 'YYYY').startOf('year').toDate(),
    maxDate: _moment.default.utc().add(10, 'years').endOf('year').toDate(),
    // Computed
    year: Ember.computed('value', 'format', function () {
      var value = Ember.get(this, 'value'),
          format = Ember.get(this, 'format');

      return _moment.default.utc(value, format).format('YYYY');
    }),
    yearOptions: Ember.computed('minDate', 'maxDate', function () {
      var minDate = Ember.get(this, 'minDate'),
          maxDate = Ember.get(this, 'maxDate'),
          year = (0, _moment.default)(minDate),
          options = [];
      while (!year.isAfter((0, _moment.default)(maxDate), 'year')) {
        options.push(year.format('YYYY'));
        year.add(1, 'year');
      }
      return options;
    }),
    // Actions
    actions: {
      setYear: function setYear(selected) {
        var action = Ember.get(this, 'onChange'),
            minDate = Ember.get(this, 'minDate'),
            maxDate = Ember.get(this, 'maxDate'),
            format = Ember.get(this, 'format'),
            year = selected;

        var date = (0, _moment.default)(year, 'YYYY');

        // make sure the week selected is not outside the range
        if (date.isBefore(minDate)) {
          date = (0, _moment.default)(minDate);
        } else if (date.isAfter(maxDate)) {
          date = (0, _moment.default)(maxDate);
        }

        var range = [date.startOf('year').clone(), date.endOf('year').clone()];

        if (action) {
          action(range, date.format(format));
        }
      }
    }
  });
});