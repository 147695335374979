define('ember-cli-page-object/-private/dsl', ['exports', 'ember-cli-page-object/-private/properties/as', 'ember-cli-page-object/-private/properties/blurrable', 'ember-cli-page-object/-private/properties/clickable', 'ember-cli-page-object/-private/properties/click-on-text', 'ember-cli-page-object/-private/properties/contains', 'ember-cli-page-object/-private/properties/fillable', 'ember-cli-page-object/-private/properties/focusable', 'ember-cli-page-object/-private/properties/is-hidden', 'ember-cli-page-object/-private/properties/is-present', 'ember-cli-page-object/-private/properties/is-visible', 'ember-cli-page-object/-private/properties/text', 'ember-cli-page-object/-private/properties/value', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/compatibility'], function (exports, _as, _blurrable, _clickable, _clickOnText, _contains, _fillable, _focusable, _isHidden, _isPresent, _isVisible, _text, _value, _helpers, _compatibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var thenDescriptor = {
    isDescriptor: true,
    value: function value() {
      var _promise;

      // In RFC268 tests, we need to wait on the promise returned from the actual
      // test helper, rather than a global method such as `wait`. So, we store the
      // promise on the root of the (chained) tree so we can find it here and use
      // it.
      var promise = (0, _helpers.getRoot)(this)._promise;
      if (!promise) {
        promise = (window.wait || _compatibility.wait)();
      }
      return (_promise = promise).then.apply(_promise, arguments);
    }
  };

  var dsl = {
    as: _as.as,
    blur: (0, _blurrable.blurrable)(),
    click: (0, _clickable.clickable)(),
    clickOn: (0, _clickOnText.clickOnText)(),
    contains: (0, _contains.contains)(),
    fillIn: (0, _fillable.fillable)(),
    focus: (0, _focusable.focusable)(),
    isHidden: (0, _isHidden.isHidden)(),
    isPresent: (0, _isPresent.isPresent)(),
    isVisible: (0, _isVisible.isVisible)(),
    select: (0, _fillable.fillable)(),
    text: (0, _text.text)(),
    then: thenDescriptor,
    value: (0, _value.value)()
  };

  exports.default = dsl;
});