define('ember-bem-sauce/mixins/bem-component', ['exports', 'ember-bem-sauce/utils/get-bem-modifiers', 'ember-get-config'], function (exports, _getBemModifiers, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var isEmpty = Ember.isEmpty,
      copy = Ember.copy,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      defineProperty = Ember.defineProperty,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    base: null,
    modifiers: [],
    classNameBindings: [],
    debugBem: false,
    // Computed
    b: alias('base'),
    componentBaseClasses: computed('base', 'm', 'tagName', function () {
      // do not add class bindings for naked components
      if (Ember.get(this, 'tagName') === '') {
        return;
      }
      var globalBase = Ember.get(_emberGetConfig.default, 'bem-sauce.globalBaseClass'),
          base = Ember.get(this, 'base'),
          classNames = Ember.A();

      if (globalBase) {
        classNames.pushObject(globalBase);
      }
      classNames.pushObject(base);

      Ember.get(this, 'm').forEach(function (modifier) {
        classNames.pushObject(base + '--' + modifier);
      });
      return classNames.join(' ');
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this._defineModifierComputedProperty();
      this._addCompomentClassBindings();
    },
    registerModifier: function registerModifier(modifier) {
      var m = Ember.get(this, 'modifiers');
      if (isEmpty(m)) {
        m = Ember.A([]);
        Ember.set(this, 'modifiers', m);
      }
      m.pushObject(modifier);

      this._defineModifierComputedProperty();
    },
    registerModifiers: function registerModifiers(modifiers) {
      var m = Ember.get(this, 'modifiers');
      if (isEmpty(m)) {
        m = Ember.A([]);
        Ember.set(this, 'modifiers', m);
      }
      m.pushObjects(modifiers);

      this._defineModifierComputedProperty();
    },

    /**
     * Add a classNameBinding computed property
     * that will add base classes
     */
    _addCompomentClassBindings: function _addCompomentClassBindings() {
      if (Ember.get(this, 'tagName') === '') {
        return;
      }
      if (Ember.get(this, 'debugBem')) {
        Ember.Logger.log('_addCompomentClassBindings');
      }
      // Get existing bindings
      var classNameBindings = Ember.get(this, 'classNameBindings');
      // Create of modify classNameBindings
      if (isEmpty(classNameBindings)) {
        classNameBindings = [];
      } else {
        classNameBindings = copy(classNameBindings);
      }
      // Add class computed property
      classNameBindings.push('componentBaseClasses');
      Ember.set(this, 'classNameBindings', classNameBindings);
    },

    /**
     * Create a computed property that will observe all properties
     * defined in the "modifiers" property.
     * This computed enables us to clean up use of the bem helper so
     * you only need pass one modifier argument.
     */
    _defineModifierComputedProperty: function _defineModifierComputedProperty() {
      if (Ember.get(this, 'debugBem')) {
        Ember.Logger.log('_defineModifierComputedProperty');
      }
      // get all modifier property strings
      var args = copy(Ember.get(this, 'modifiers')).map(function (mod) {
        // remove any custom modifiers so we
        // observe the correct property
        if (mod.indexOf(':') !== -1) {
          return mod.split(':')[0];
        }
        // look out for wildcards
        if (mod.indexOf('*') === 0) {
          return mod.substr(1);
        }

        return mod;
      });
      if (Ember.get(this, 'debugBem')) {
        Ember.Logger.log(args);
      }
      // add the computed function
      args.push(function () {
        var modifiers = (0, _getBemModifiers.default)(Ember.get(this, 'modifiers'), this);
        if (Ember.get(this, 'debugBem')) {
          Ember.Logger.log(modifiers);
        }
        return modifiers;
      });
      // define computed using spread operator
      defineProperty(this, 'm', computed.apply(undefined, _toConsumableArray(args)));
    }
  });
});