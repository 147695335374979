define('ember-cli-page-object/-private/execution_context/helpers', ['exports', 'ember-cli-page-object/-private/better-errors', '-jquery'], function (exports, _betterErrors, _jquery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fillElement = fillElement;
  exports.assertFocusable = assertFocusable;


  /**
   * @private
   *
   * Fills inputs, textareas, or contenteditable elements with the passed-in content.
   *
   * @param {jQuery} $selection              jQuery object containing collection of DOM elements to fill in
   * @param {string} content                 Content to be inserted into the target element(s)
   * @param {Object} options                 Options for error reporting
   * @param {string} options.selector        jQuery selector used to target element(s) to fill in
   * @param {Ceibo} options.pageObjectNode   PageObject node containing the method which, when invoked, resulted in this call to `fillElement`
   * @param {string} options.pageObjectKey   Key of method on PageObject which, when invoked, resulted in this call to `fillElement`
   * @return
   *
   * @throws Will throw an error if called on a contenteditable element that has `contenteditable="false"`
   */
  function fillElement(selection, content, _ref) {
    var selector = _ref.selector,
        pageObjectNode = _ref.pageObjectNode,
        pageObjectKey = _ref.pageObjectKey;

    var $selection = (0, _jquery.default)(selection);

    if ($selection.is('[contenteditable][contenteditable!="false"]')) {
      $selection.html(content);
    } else if ($selection.is('[contenteditable="false"]')) {
      (0, _betterErrors.throwBetterError)(pageObjectNode, pageObjectKey, 'Element cannot be filled because it has `contenteditable="false"`.', {
        selector: selector
      });
    } else {
      $selection.val(content);
    }
  }

  /**
   * @private
   *
   * Given an element, asserts that element is focusable/blurable
   *
   * @param {Element} element - the element to check
   */
  function assertFocusable(element, _ref2) {
    var selector = _ref2.selector,
        pageObjectNode = _ref2.pageObjectNode,
        pageObjectKey = _ref2.pageObjectKey;

    var $element = (0, _jquery.default)(element);

    var error = void 0;

    if ($element.is(':hidden')) {
      error = 'hidden';
    } else if ($element.is(':disabled')) {
      error = 'disabled';
    } else if ($element.is('[contenteditable="false"]')) {
      error = 'contenteditable="false"';
    } else if (!$element.is(':input, a[href], area[href], iframe, [contenteditable], [tabindex]')) {
      error = 'not a link, input, form element, contenteditable, iframe, or an element with tabindex';
    }

    if (error) {
      (0, _betterErrors.throwBetterError)(pageObjectNode, pageObjectKey, 'Element is not focusable because it is ' + error, {
        selector: selector
      });
    }
  }
});