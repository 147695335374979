define('ember-ux-sauce/components/uxs-toolbar', ['exports', 'ember-ux-sauce/templates/components/uxs-toolbar', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsToolbar, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textable.default, {
    base: 'uxs-toolbar',
    layout: _uxsToolbar.default,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        style: 'primary'
      };
    }
  });
});