define('ember-ux-sauce/components/uxs-dt', ['exports', 'ember-ux-sauce/templates/components/uxs-dt', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsDt, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Term = Ember.Component.extend(_textable.default, {
    base: 'uxs-dl__term',
    layout: _uxsDt.default,
    tagName: 'dt'
  });

  Term.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Term;
});