define('ember-cli-page-object/-private/execution_context/acceptance-native-events', ['exports', 'ember-cli-page-object/-private/execution_context/native-events-context', 'ember-cli-page-object/-private/compatibility', 'ember-native-dom-helpers'], function (exports, _nativeEventsContext, _compatibility, _emberNativeDomHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = AcceptanceNativeEventsExecutionContext;
  function AcceptanceNativeEventsExecutionContext(pageObjectNode) {
    _nativeEventsContext.default.call(this, pageObjectNode);
  }

  AcceptanceNativeEventsExecutionContext.prototype = Object.create(_nativeEventsContext.default.prototype);

  AcceptanceNativeEventsExecutionContext.prototype.visit = function () {
    _emberNativeDomHelpers.visit.apply(undefined, arguments);
    return this.pageObjectNode;
  };

  AcceptanceNativeEventsExecutionContext.prototype.runAsync = function (cb) {
    var _this = this;

    (window.wait || _compatibility.wait)().then(function () {
      cb(_this);
    });

    return this.chainable();
  };
});