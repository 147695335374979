define('ember-ux-sauce/components/uxs-window', ['exports', 'ember-ux-sauce/templates/components/uxs-window', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsWindow, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    attributeBindings: ['backgroundImage:style'],
    base: 'uxs-window',
    layout: _uxsWindow.default,
    // Computed
    splash: Ember.computed.bool('image'),
    backgroundImage: Ember.computed('image', function () {
      var image = Ember.get(this, 'image');
      if (image) {
        return 'background-image:url(' + image + ')';
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['splash', '*style']);
    }
  });
});