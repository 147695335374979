define('ember-cli-mirage/orm/collection', ['exports', 'ember-cli-mirage/assert', 'lodash/invokeMap'], function (exports, _assert, _invokeMap2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Collection = function () {
    function Collection(modelName) {
      var models = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      _classCallCheck(this, Collection);

      (0, _assert.default)(modelName && typeof modelName === 'string', 'You must pass a `modelName` into a Collection');

      this.modelName = modelName;
      this.models = models;
    }

    /**
     * Number of models in the collection.
     *
     * @property length
     * @type Number
     * @public
     */


    _createClass(Collection, [{
      key: 'update',
      value: function update() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        _invokeMap2.default.apply(undefined, [this.models, 'update'].concat(args));

        return this;
      }
    }, {
      key: 'destroy',
      value: function destroy() {
        (0, _invokeMap2.default)(this.models, 'destroy');

        return this;
      }
    }, {
      key: 'save',
      value: function save() {
        (0, _invokeMap2.default)(this.models, 'save');

        return this;
      }
    }, {
      key: 'reload',
      value: function reload() {
        (0, _invokeMap2.default)(this.models, 'reload');

        return this;
      }
    }, {
      key: 'add',
      value: function add(model) {
        this.models.push(model);

        return this;
      }
    }, {
      key: 'remove',
      value: function remove(model) {
        var _models$filter = this.models.filter(function (m) {
          return m.toString() === model.toString();
        }),
            _models$filter2 = _slicedToArray(_models$filter, 1),
            match = _models$filter2[0];

        if (match) {
          var i = this.models.indexOf(match);
          this.models.splice(i, 1);
        }

        return this;
      }
    }, {
      key: 'includes',
      value: function includes(model) {
        return this.models.filter(function (m) {
          return m.toString() === model.toString();
        }).length > 0;
      }
    }, {
      key: 'filter',
      value: function filter(f) {
        var filteredModels = this.models.filter(f);

        return new Collection(this.modelName, filteredModels);
      }
    }, {
      key: 'sort',
      value: function sort(f) {
        var sortedModels = this.models.concat().sort(f);

        return new Collection(this.modelName, sortedModels);
      }
    }, {
      key: 'slice',
      value: function slice() {
        var _models;

        var slicedModels = (_models = this.models).slice.apply(_models, arguments);

        return new Collection(this.modelName, slicedModels);
      }
    }, {
      key: 'mergeCollection',
      value: function mergeCollection(collection) {
        this.models = this.models.concat(collection.models);

        return this;
      }
    }, {
      key: 'toString',
      value: function toString() {
        return 'collection:' + this.modelName + '(' + this.models.map(function (m) {
          return m.id;
        }).join(',') + ')';
      }
    }, {
      key: 'length',
      get: function get() {
        return this.models.length;
      }
    }]);

    return Collection;
  }();

  exports.default = Collection;
});