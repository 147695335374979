define('ember-ux-date-sauce/components/uxs-date-range/custom', ['exports', 'ember-ux-date-sauce/templates/components/uxs-date-range/custom', 'moment'], function (exports, _custom, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _custom.default,
    format: 'DD/MM/YY',
    tagName: '',
    minDate: null,
    maxDate: null,
    // Computed
    from: Ember.computed('value', 'format', function () {
      var value = Ember.get(this, 'value').split('..')[0],
          format = Ember.get(this, 'format');

      return _moment.default.utc(value, format).format(format);
    }),
    to: Ember.computed('value', 'format', function () {
      var value = Ember.get(this, 'value').split('..')[1],
          format = Ember.get(this, 'format');
      return _moment.default.utc(value, format).format(format);
    }),
    minFromDate: Ember.computed('minDate', function () {
      var min = Ember.get(this, 'minDate');
      if (min) {
        return min;
      }
      return null;
    }),
    maxFromDate: Ember.computed('maxDate', 'to', function () {
      var format = Ember.get(this, 'format'),
          to = _moment.default.utc(Ember.get(this, 'to'), format);
      if (to) {
        return to.subtract(1, 'day').toDate();
      }

      var max = Ember.get(this, 'maxDate');
      if (max) {
        return max;
      }
      return null;
    }),
    minToDate: Ember.computed('minDate', 'from', function () {
      var format = Ember.get(this, 'format'),
          from = _moment.default.utc(Ember.get(this, 'from'), format);
      if (from) {
        return from.add(1, 'day').toDate();
      }

      var min = Ember.get(this, 'minDate');
      if (min) {
        return min;
      }
      return null;
    }),
    maxToDate: Ember.computed('maxDate', function () {
      var max = Ember.get(this, 'maxDate');
      if (max) {
        return max;
      }
      return null;
    }),
    // Actions
    actions: {
      setFrom: function setFrom(dates, formattedDate, ref) {
        var action = Ember.get(this, 'onChange'),
            format = Ember.get(this, 'format'),
            from = _moment.default.utc(formattedDate, format).startOf('day').clone(),
            to = (0, _moment.default)(Ember.get(this, 'to'), format),
            range = [from, to],
            formattedRange = from.format(format) + '..' + to.format(format);

        if (action) {
          action(range, formattedRange, ref);
        }
      },
      setTo: function setTo(dates, formattedDate, ref) {
        var action = Ember.get(this, 'onChange'),
            format = Ember.get(this, 'format'),
            from = (0, _moment.default)(Ember.get(this, 'from'), format),
            to = _moment.default.utc(formattedDate, format).startOf('day').clone(),
            range = [from, to],
            formattedRange = from.format(format) + '..' + to.format(format);

        if (action) {
          action(range, formattedRange, ref);
        }
      }
    }
  });
});