define('ember-cli-page-object/-private/execution_context/rfc268', ['exports', '-jquery', 'ember-cli-page-object/-private/helpers', 'ember-cli-page-object/-private/compatibility', 'ember-cli-page-object/-private/better-errors', 'ceibo'], function (exports, _jquery, _helpers, _compatibility, _betterErrors, _ceibo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = ExecutionContext;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function ExecutionContext(pageObjectNode) {
    this.pageObjectNode = pageObjectNode;
  }

  ExecutionContext.prototype = {
    run: function run(cb) {
      return cb(this);
    },
    runAsync: function runAsync(cb) {
      var _this = this;

      var root = (0, _helpers.getRoot)(this.pageObjectNode);
      var isChained = !root._chainedTree;

      if (isChained) {
        // Already chained, so our root is the root of the chained tree, and we
        // need to wait on its promise if it has one so the previous call can
        // resolve before we run ours.
        root._promise = Ember.RSVP.resolve(root._promise).then(function () {
          return cb(_this);
        });
      } else {
        // Not a chained call, so store our method's return on the chained root
        // so that chained calls can find it to wait on it.
        root._chainedTree._promise = cb(this);
      }

      return this.chainable();
    },
    chainable: function chainable() {
      // See explanation in `create.js` -- here instead of returning the node on
      // which our method was invoked, we find and return our node's mirror in the
      // chained tree so calls to it can be recognized as chained calls, and
      // trigger the chained-call waiting behavior.
      var root = (0, _helpers.getRoot)(this.pageObjectNode);
      var isChained = !root._chainedTree;

      if (isChained) {
        // Already chained, so our node is in the chained tree
        return this.pageObjectNode;
      } else {
        // Not already chained, so we need to look up our equivalent node in the
        // chained tree and return that. We do it by walking up the tree
        // collecting node keys to build a path to our node, and then use that
        // to walk back down the chained tree to our mirror node.
        var path = [];
        var node = void 0;

        for (node = this.pageObjectNode; node; node = _ceibo.default.parent(node)) {
          path.unshift(_ceibo.default.meta(node).key);
        }
        // The path will end with the root's key, 'root', so shift that back off
        path.shift();

        node = root._chainedTree;
        path.forEach(function (key) {
          // Normally an item's key is just its property name, but collection
          // items' keys also include their index. Collection item keys look like
          // `foo[2]` and legacy collection item keys look like `foo(2)`.
          var match = void 0;
          if (match = /\[(\d+)\]$/.exec(key)) {
            var _match = match,
                _match2 = _slicedToArray(_match, 2),
                indexStr = _match2[0],
                index = _match2[1];

            var name = key.slice(0, -indexStr.length);
            node = node[name].objectAt(parseInt(index, 10));
          } else if (match = /\((\d+)\)$/.exec(key)) {
            var _match3 = match,
                _match4 = _slicedToArray(_match3, 2),
                _indexStr = _match4[0],
                _index = _match4[1];

            var _name = key.slice(0, -_indexStr.length);
            node = node[_name](parseInt(_index, 10));
          } else {
            node = node[key];
          }
        });
        return node;
      }
    },
    visit: function visit(path) {
      return (0, _compatibility.visit)(path);
    },
    click: function click(selector, container, options) {
      return this.invokeHelper(selector, options, _compatibility.click);
    },
    fillIn: function fillIn(selector, container, options, content) {
      return this.invokeHelper(selector, options, _compatibility.fillIn, content);
    },
    triggerEvent: function triggerEvent(selector, container, options, eventName, eventOptions) {
      // `keyCode` is a deprecated property.
      // @see: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      // Due to this deprecation `ember-native-dom-helpers` doesn't accept `keyCode` as a `KeyboardEvent` option.
      if (typeof eventOptions.key === 'undefined' && typeof eventOptions.keyCode !== 'undefined') {
        eventOptions.key = eventOptions.keyCode.toString();
        delete eventOptions.keyCode;
      }

      return this.invokeHelper(selector, options, _compatibility.triggerEvent, eventName, eventOptions);
    },
    focus: function focus(selector, options) {
      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);
      return this.invokeHelper(selector, options, _compatibility.focus);
    },
    blur: function blur(selector, options) {
      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);
      return this.invokeHelper(selector, options, _compatibility.blur);
    },
    assertElementExists: function assertElementExists(selector, options) {
      var result = this.getElements(selector, options);

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }
    },
    find: function find(selector, options) {
      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);
      var result = this.getElements(selector, options);

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      return result;
    },
    findWithAssert: function findWithAssert(selector, options) {
      selector = (0, _helpers.buildSelector)(this.pageObjectNode, selector, options);
      var result = this.getElements(selector, options);

      (0, _helpers.guardMultiple)(result, selector, options.multiple);

      if (result.length === 0) {
        (0, _betterErrors.throwBetterError)(this.pageObjectNode, options.pageObjectKey, _betterErrors.ELEMENT_NOT_FOUND, { selector: selector });
      }

      return result;
    },
    getElements: function getElements(selector, options) {
      var container = options.testContainer || (0, _helpers.findClosestValue)(this.pageObjectNode, 'testContainer');
      if (container) {
        return (0, _jquery.default)(selector, container);
      } else {
        return (0, _jquery.default)(selector, (0, _compatibility.getContext)().element);
      }
    },
    invokeHelper: function invokeHelper(selector, options, helper) {
      var _this2 = this;

      var element = this.getElements(selector, options)[0];

      for (var _len = arguments.length, args = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      return helper.apply(undefined, [element].concat(_toConsumableArray(args))).catch(function (e) {
        (0, _betterErrors.throwBetterError)(_this2.pageObjectNode, options.pageObjectKey, e.message || e.toString(), { selector: selector });
      });
    }
  };
});